import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Dropdown,
    Icon,
    Link,
    Loading,
    Portal,
    PropertyCategoriesContext,
    SendLgsrsModal,
} from "../../../components";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import { useToast, useToggle } from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useDownloadPaperwork from "../../../hooks/useDownloadPaperwork";
import useQueryString from "../../../hooks/useQueryString";
import {
    DocumentsCsvReportName,
    IPaperwork,
    useDocumentStatus,
    useDocumentsDownload,
    useMarkAsSentByPost,
} from "../../../utils/api/lgsrs";
import { clearCache } from "../../../utils/cache";
import downloadFile from "../../../utils/files";

const DocumentsTable = ({
    status = "All",
    csvReportName = "Documents all",
}: IDocumentsTableProps) => {
    const { t } = useTranslation();
    const { visible, show, hide } = useToggle();
    // const {
    //     visible: bulkModalVisible,
    //     hide: bulkModalHide,
    //     show: bulkModalShow,
    // } = useToggle();
    const toast = useToast();
    const csvToast = useToast();
    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const documents = useDocumentStatus({
        status: status,
        sortProperty: "jobDate",
        sortDirection: "asc",
    });
    const documentsDownload = useDocumentsDownload();
    const downloadPaperwork = useDownloadPaperwork();
    const markAsSent = useMarkAsSentByPost();

    const [documentIds, setDocumentIds] = useState<number[]>([]);
    // const [documentsToSend, setDocumentsToSend] = useState<
    //     IBulkSendPaperwork[]
    // >([]);
    const [tenantEmail, setTenantEmail] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const openDocument = (item: IPaperwork) => {
        downloadPaperwork.download(item.id, item.documentUrl, true).subscribe();
    };

    const downloadDocuments = (ids: number[]) => {
        documentsDownload.download(ids).subscribe((url) => {
            downloadFile(url);
        });
    };

    const markLgsrsAsSent = (ids: number[]) => {
        markAsSent.markAsSentByPost(ids).subscribe(() => {
            setToastMessage(
                t(
                    `Document${
                        ids.length === 1 ? "" : "s"
                    } marked as sent via post`,
                ),
            );
            toast.show();
            clearCache();
            documents.refresh();
        });
    };

    const openSendDocumentsModal = (id: number, email = "") => {
        setDocumentIds([id]);
        setTenantEmail(email);
        show();
    };

    // const openBulkSendDocumentsModal = (
    //     bulkDocuments: IBulkSendPaperwork[],
    // ) => {
    //     setDocumentsToSend(bulkDocuments);
    //     bulkModalShow();
    // };

    const { getQueryString } = useQueryString(documents.search);

    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.lgsrsApiUrl}/flagged/export/documents`,
        filters: documents.filters,
        filterColumns: (vc) => !["actions"].includes(vc.key),
        search: getQueryString(),
        showToast: csvToast.show,
        csvReportName,
    });

    const columns = useMemo(() => {
        const renderJobId = (value: number) => (
            <Link url={`/jobs/jobs/${value}`}>{t("View Job")}</Link>
        );

        const renderAddressString = (value: string, row: IPaperwork) => (
            <Link url={`/management/properties/${row.propertyId}`}>
                {value}
            </Link>
        );

        const renderDocumentType = (value: string, row: IPaperwork) => (
            <Button onClick={() => openDocument(row)}>{value}</Button>
        );

        const renderActions = (_: unknown, row: IPaperwork) => (
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    {
                        label: t("Send Document"),
                        onClick: () =>
                            openSendDocumentsModal(row.id, row.tenantEmail),
                    },
                    {
                        label: t("View Document"),
                        onClick: () => openDocument(row),
                    },
                    {
                        label: t("Mark as sent via post"),
                        onClick: () => markLgsrsAsSent([row.id]),
                    },
                ]}
            />
        );

        const col: { [key: string]: ITableColumn<IPaperwork> } = {
            jobId: {
                title: t("View"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderJobId,
            },
            jobDate: {
                title: t("Job Date"),
                type: "date",
            },
            "fuelType.displayName": {
                title: t("Fuel Type"),
                filterable: false,
            },
            addressString: {
                title: t("Property"),
                filterable: false,
                render: renderAddressString,
            },
            uprn: {
                title: t("UPRN"),
                filterable: false,
            },
            tenantEmail: {
                title: t("Tenant Email Address"),
                filterable: false,
                sortable: false,
            },
            tenantName: {
                title: t("Tenant Name"),
                filterable: false,
                sortable: false,
            },
            tenantTelephoneNumber: {
                title: t("Tenant Telephone Number"),
                filterable: false,
                sortable: false,
            },
            auditStatus: {
                title: t("Audit Status"),
            },
            "paperworkStatus.status": {
                title: t("EICR Status"),
                type: "string",
                hidden:
                    selectedCategories.length > 0 &&
                    !selectedCategories.some((c) => c.id === 21),
            },
            "engineer.name": {
                title: t("Engineer"),
            },
            documentType: {
                title: t("Document Type"),
                render: renderDocumentType,
            },
            ...(status !== "Unsent" && {
                "latestDistribution.distributionType": {
                    title: t("Last Send Method"),
                    filterable: false,
                    sortable: false,
                },
            }),
            ...(status !== "Unsent" && {
                "latestDistribution.createdAt": {
                    title: t("Last Send Date"),
                    type: "date",
                    filterable: false,
                    sortable: false,
                },
            }),
            ...(status !== "Unsent" && {
                "latestDistribution.sentBy": {
                    title: t("Last Sent By"),
                    filterable: false,
                    sortable: false,
                },
            }),
            ...(status !== "Unsent" && {
                "latestDistribution.recipient": {
                    title: t("Last Sent To"),
                    filterable: false,
                    sortable: false,
                },
            }),
            status: {
                title: t("Status"),
                filterable: false,
                sortable: false,
                hidden: status !== "All",
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategories, t, status]);

    const bulkActions = useMemo<IBulkAction[]>(
        () => [
            // Temporarily restricted XT-758
            // {
            //     value: "send",
            //     label: t("Send documents"),
            //     onSubmit: (ids) => {
            //         openBulkSendDocumentsModal(
            //             ids
            //                 .map((id) => {
            //                     const document = documents.records.find(
            //                         (d) => d.id === id && d.tenantEmail !== "",
            //                     );
            //                     return {
            //                         tenantEmail: document?.tenantEmail,
            //                         id: document?.id,
            //                     };
            //                 })
            //                 .filter(
            //                     (document): document is IBulkSendPaperwork =>
            //                         document.id !== undefined,
            //                 ),
            //         );
            //     },
            // },
            {
                value: "mark",
                label: t("Mark as sent via post"),
                onSubmit: (ids) => {
                    markLgsrsAsSent(
                        ids
                            .map((id) => {
                                const document = documents.records.find(
                                    (d) => d.id === id,
                                );
                                return (document && document.id) || -1;
                            })
                            .filter((id) => id !== -1),
                    );
                },
            },
            {
                value: "download",
                label: t("Download as ZIP"),
                onSubmit: downloadDocuments,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [documents.records, t],
    );

    return documents.loaded ? (
        <>
            <Table
                preferences="unsent-documents-table"
                columns={columns}
                bulkActions={bulkActions}
                alternateCsvFunction={handleDownloadClick}
                hideChildComponent={csvToast.visible}
                {...documents}
            />
            {visible && (
                <SendLgsrsModal
                    tenantEmail={tenantEmail}
                    paperworkIds={documentIds}
                    hide={hide}
                />
            )}
            {/* {bulkModalVisible && (
                <BulkSendDocumentsModal
                    documentsToSend={documentsToSend}
                    hide={bulkModalHide}
                />
            )} */}
            {toast.visible && (
                <Portal>
                    <Toast>{toastMessage}</Toast>
                </Portal>
            )}
            {csvToast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    ) : (
        <Loading />
    );
};

interface IDocumentsTableProps {
    status: "Unsent" | "Sent" | "All";
    csvReportName: DocumentsCsvReportName;
}

export default DocumentsTable;
