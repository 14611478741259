import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import useActiveTabFromUrl from "../../hooks/useActiveTabFromUrl";
import {
    AuditStatus,
    QualifiedSupervisorCsvReportName,
    useAuditCounts,
} from "../../utils/api/audits";
import QualifiedSupervisorAuditsTable from "./QualifiedSupervisorAuditsTable";

const tabs: {
    status: AuditStatus;
    reportName: QualifiedSupervisorCsvReportName;
}[] = [
    {
        status: "Waiting",
        reportName: "Qs waiting",
    },
    {
        status: "Hold",
        reportName: "Qs on hold",
    },
    {
        status: "Passed",
        reportName: "Qs passed",
    },
    {
        status: "Failed",
        reportName: "Qs failed",
    },
];

const QualifiedSupervisorAudits = () => {
    const { t } = useTranslation();
    const { value, refresh } = useAuditCounts();
    const [activeTab, setActiveTab] = useActiveTabFromUrl(
        tabs.map((tab) => tab.status),
    );

    const refreshCounts = useCallback(() => refresh().subscribe(), [refresh]);

    const tabGroups = useMemo(() => {
        const dictionary: { [key: string]: number | undefined } = {};

        for (const record of value) {
            dictionary[record.statusLabel] = record.count;
        }

        return tabs.map((tab) => {
            const count = dictionary[tab.status];

            return {
                header: t(tab.status) + (count ? ` (${count})` : ""),
                status: tab.status,
                reportName: tab.reportName,
            };
        });
    }, [t, value]);

    return (
        <Card title={t("Qualified Supervisor Documents")} padding={false}>
            <TabGroup currentTab={activeTab} onActivate={setActiveTab}>
                {tabGroups.map((tab) => (
                    <TabGroupTab header={tab.header} key={tab.status}>
                        <QualifiedSupervisorAuditsTable
                            status={tab.status}
                            csvReportName={tab.reportName}
                            refreshCounts={refreshCounts}
                        />
                    </TabGroupTab>
                ))}
            </TabGroup>
        </Card>
    );
};

export default QualifiedSupervisorAudits;
