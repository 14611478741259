import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Link, Loading, Portal } from "../../components";
import Table, { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import { IContractor, useContractors } from "../../utils/api/landlords";

const Contractors = () => {
    const { t } = useTranslation();
    const contractors = useContractors({
        sortProperty: "name",
        sortDirection: "asc",
    });

    const columns = useMemo(() => {
        const col: { [key: string]: ITableColumn<IContractor> } = {
            name: {
                title: t("Name"),
                canBeToggledByUser: false,
            },
            businessRegId: {
                title: t("Business registration id"),
                filterable: false,
            },
            contactName: { title: t("Contact name") },
            telephoneNum: { title: t("Tel") },
            addressString: { title: t("Address"), filterable: false },
            verificationId: {
                title: t("Verification id"),
                filterable: false,
                sortable: false,
            },
            verificationSecret: {
                title: t("Verification secret"),
                filterable: false,
                sortable: false,
            },
        };

        return col;
    }, [t]);
    const toast = useToast();
    const { getQueryString } = useQueryString(contractors.search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.landlordsApiUrl}/contractors/export`,
        filters: contractors.filters,
        search: getQueryString(),
        showToast: toast.show,
        csvReportName: "Contractors",
    });

    return (
        <>
            <Card
                title={t("Contractors")}
                actions={
                    <Link url="/management/contractors/add">
                        {t("Add new Contractor")}
                    </Link>
                }
            >
                {contractors.loaded ? (
                    <Table
                        preferences="contractors-table"
                        columns={columns}
                        {...contractors}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default Contractors;
