import { IAmendedLgsr } from ".";
import config from "../../../config";
import useFilterable, { IFilterableParams } from "../useFilterable";

const useAmendedLgsrs = ({
    sortProperty,
    sortDirection,
}: IFilterableParams) => {
    const filterable = useFilterable<IAmendedLgsr>(
        `${config.lgsrsApiUrl}/amendedlgsrs`,
        {
            params: {
                sortProperty,
                sortDirection,
                filters: {
                    amendedDocuments: {
                        operator: "{AND}",
                        readOnly: true,
                        filters: [{ function: "=", value: "Amended LGSR" }],
                    },
                },
            },
        },
    );

    return filterable;
};

export default useAmendedLgsrs;
