import { useTranslation } from "react-i18next";
import { Card, TabGroup, TabGroupTab } from "../../components";
import DocumentsTable from "./DocumentsTable";

const DocumentStatus = () => {
    const { t } = useTranslation();

    return (
        <Card title={t("Documents")} padding={false}>
            <>
                <TabGroup>
                    <TabGroupTab header={t("Not sent")}>
                        <DocumentsTable
                            status="Unsent"
                            csvReportName="Documents not sent"
                        />
                    </TabGroupTab>
                    <TabGroupTab header={t("Sent")}>
                        <DocumentsTable
                            status="Sent"
                            csvReportName="Documents sent"
                        />
                    </TabGroupTab>
                    <TabGroupTab header={t("All")}>
                        <DocumentsTable
                            status="All"
                            csvReportName="Documents all"
                        />
                    </TabGroupTab>
                </TabGroup>
            </>
        </Card>
    );
};

export default DocumentStatus;
