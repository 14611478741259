import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ColourPill,
    Dropdown,
    Icon,
    JobType,
    Link,
    Loading,
    Modal,
    Portal,
    Table,
    UserContext,
} from "../../../components";
import ModalBody from "../../../components/Modal/ModalBody";
import ModalFooter from "../../../components/Modal/ModalFooter";
import { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import {
    useHasUserParentPermission,
    useToast,
    useToggle,
} from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import { IComplianceType } from "../../../utils/api/jobs";
import {
    AuditsCsvReportName,
    AuditStatus,
    IFlaggedLgsr,
    useFlaggedLgsrs,
} from "../../../utils/api/lgsrs";
import useJobAudit from "../../../utils/api/lgsrs/useJobAudit";
import useJobAuditLock from "../../../utils/api/lgsrs/useJobAuditLock";
import useRemoveJobAuditLock from "../../../utils/api/lgsrs/useRemoveJobAuditLock";
import { clearCache } from "../../../utils/cache";
import LgsrAuditApprover from "../LgsrAuditApprover";
import styles from "./AuditTable.module.scss";

const AuditsTable = ({
    inbox = false,
    status,
    csvReportName,
    onAuditCompleted,
}: IAuditsTableProps) => {
    const { t } = useTranslation();
    const lgsrs = useFlaggedLgsrs(inbox, status);
    const jobAudit = useJobAudit();
    const { lockAudit } = useJobAuditLock();
    const { removeLock } = useRemoveJobAuditLock();
    const {
        show: auditingShow,
        hide: auditingHide,
        visible: auditingVisible,
    } = useToggle();

    const [auditingLgsrs, setAuditingLgsrs] = useState<IFlaggedLgsr[]>([]);
    const [auditingJobId, setAuditingJobId] = useState<number | undefined>();

    const toast = useToast();
    const csvReportToast = useToast();

    const userContext = useContext(UserContext);

    const completeAudit = useCallback(() => {
        auditingHide();

        clearCache();
        lgsrs.refresh();

        onAuditCompleted();
    }, [auditingHide, lgsrs, onAuditCompleted]);

    const {
        show: confirmCloseShow,
        hide: confirmCloseHide,
        visible: confirmCloseVisible,
    } = useToggle();

    const auditModalClosed = useCallback(() => {
        confirmCloseShow();
    }, [confirmCloseShow]);

    const auditModelCloseConfirm = useCallback(() => {
        if (auditingJobId && !inbox) {
            removeLock([auditingJobId]).subscribe();
            setAuditingJobId(undefined);
        }
        confirmCloseHide();
        auditingHide();
    }, [auditingHide, auditingJobId, confirmCloseHide, inbox, removeLock]);

    const [auditSingleDocument, setAuditSingleDocument] = useState(false);

    const [readOnlyMode, setReadOnlyMode] = useState(false);

    const canRemoveAuditHold = useHasUserParentPermission(
        "portal_admin",
        jobAudit.value?.property.landlord.userId,
    );

    const auditNextLgsr = useCallback(
        (lgsrList: IFlaggedLgsr[]) => {
            const list = [...lgsrList];
            const nextLgsr = list.shift();

            if (nextLgsr) {
                setAuditingJobId(nextLgsr.id);

                // check if there is a lock on the job
                lockAudit(nextLgsr.id).subscribe((lock) => {
                    if (lock.successful) {
                        setAuditingLgsrs(list);

                        jobAudit.reset();
                        clearCache();
                        jobAudit.getJobAudit(nextLgsr.id).subscribe();

                        auditingShow();
                    } else {
                        setAuditSingleDocument((a) => {
                            (a || auditSingleDocument) && toast.show();
                            return a;
                        });

                        auditNextLgsr(list);
                    }
                });
            } else {
                completeAudit();
            }
        },
        [
            auditSingleDocument,
            auditingShow,
            completeAudit,
            jobAudit,
            lockAudit,
            toast,
        ],
    );

    const goToNextLgsr = () => auditNextLgsr(auditingLgsrs);

    const startAuditing = useCallback(
        (ids: number[] = []) => {
            setAuditSingleDocument(ids.length === 1);

            setReadOnlyMode(status !== "Approval Required");

            if (ids.length === 0) {
                auditNextLgsr(lgsrs.records);
            } else {
                auditNextLgsr(
                    lgsrs.records.filter((lgsr) => ids.includes(lgsr.id)),
                );
            }
        },
        [auditNextLgsr, lgsrs.records, status],
    );

    const readonlyView = useCallback(
        (id: number) => {
            setReadOnlyMode(true);

            jobAudit.reset();
            jobAudit.getJobAudit(id).subscribe();
            auditingShow();
        },
        [auditingShow, jobAudit],
    );

    const handleRemoveLock = useCallback(
        (ids: number[]) => {
            removeLock(ids).subscribe(() => {
                clearCache();
                lgsrs.refresh();
            });
        },
        [lgsrs, removeLock],
    );

    const auditorFormatter = useCallback(
        (value: string) =>
            value === "" ? "''" : value === null ? t("Unassigned") : value,
        [t],
    );

    const { getQueryString } = useQueryString(lgsrs.search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.lgsrsApiUrl}/flagged/export`,
        filters: lgsrs.filters,
        filterColumns: (vc) => !["actions", "auditButton"].includes(vc.key),
        search: getQueryString(),
        showToast: csvReportToast.show,
        csvReportName,
    });

    const renderAuditButton = useCallback(
        (value: string, row: IFlaggedLgsr) => {
            return (row.auditorId && row.auditorId === userContext.user.id) ||
                !row.auditorId ? (
                <Button onClick={startAuditing} clickParams={[[row.id]]}>
                    {t("Audit")}
                </Button>
            ) : (
                canRemoveAuditHold && (
                    <Button onClick={handleRemoveLock} clickParams={[[row.id]]}>
                        {t("Remove hold")}
                    </Button>
                )
            );
        },
        [
            userContext.user.id,
            startAuditing,
            t,
            canRemoveAuditHold,
            handleRemoveLock,
        ],
    );

    const renderJobId = useCallback(
        (value: number) => <Link url={`/jobs/jobs/${value}`}>#{value}</Link>,
        [],
    );

    const renderComplianceType = useCallback(
        (value: IComplianceType) =>
            value && (
                <ColourPill
                    customColour={value.colour}
                    value={t(value.displayName)}
                />
            ),
        [t],
    );

    const renderJobType = useCallback(
        (value: string) => <JobType jobType={value} />,
        [],
    );

    const renderRemdialAction = useCallback(
        (value: string, row: IFlaggedLgsr) =>
            value && (
                <Button onClick={readonlyView} clickParams={[row.id]}>
                    {"Remedial Action"}
                </Button>
            ),
        [readonlyView],
    );

    const renderCommentCount = useCallback(
        (value: string, row: IFlaggedLgsr) => (
            <Button onClick={readonlyView} clickParams={[row.id]}>
                <Icon
                    icon="comment"
                    size={16}
                    cssRules={{ marginRight: "5px" }}
                    ariaLabel={t("Comments")}
                />
                {value}
            </Button>
        ),
        [readonlyView, t],
    );

    const renderActions = useCallback(
        (_: unknown, row: IFlaggedLgsr) => (
            <Dropdown
                label={<Icon icon="more" ariaLabel={t("Actions")} />}
                size="small"
                items={[
                    ...((!inbox && status === "Approval Required") ||
                    (inbox &&
                        row.auditorId &&
                        row.auditorId === userContext.user.id)
                        ? [
                              {
                                  label: t("Audit"),
                                  onClick: () => startAuditing([row.id]),
                              },
                          ]
                        : [
                              {
                                  label: t("View audit"),
                                  onClick: () => readonlyView(row.id),
                              },
                          ]),
                    ...(canRemoveAuditHold && inbox
                        ? [
                              {
                                  label: t("Remove hold"),
                                  onClick: () => handleRemoveLock([row.id]),
                              },
                          ]
                        : []),
                ]}
            />
        ),
        [
            inbox,
            status,
            userContext.user.id,
            t,
            canRemoveAuditHold,
            startAuditing,
            readonlyView,
            handleRemoveLock,
        ],
    );

    const columns = useMemo(() => {
        const col: { [key: string]: ITableColumn<IFlaggedLgsr> } = {
            ...(status === "Approval Required" && {
                auditButton: {
                    title: t("Audit"),
                    filterable: false,
                    canBeToggledByUser: false,
                    sortable: false,

                    field: "id",
                    render: renderAuditButton,
                },
            }),
            id: {
                title: t("Job Id"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderJobId,
            },
            documentCount: {
                title: t("Documents"),
                type: "number",
            },
            addressString: {
                title: t("Address"),
                filterable: false,
            },
            date: {
                title: t("Date"),
                type: "date",
            },
            engineerString: {
                title: t("Engineer"),
            },
            origin: {
                title: t("Origin"),
                render: (_, row) =>
                    row.recoveryJob ? t("Manual upload") : t("Job"),
            },
            documentType: {
                title: t("Document Type"),
                sortable: false,
                render: (_, row) =>
                    row.documentTypes.length > 1
                        ? t("Multi")
                        : row.documentTypes.join(", "),
            },
            complianceType: {
                title: t("Compliance Category"),

                render: renderComplianceType,
            },
            jobType: {
                title: t("Job Type"),
                render: renderJobType,
            },
            remedialAction: {
                title: t("Remedial Action"),
                render: renderRemdialAction,
                sortable: false,
            },
            commentCount: {
                title: t("Comment Count"),
                filterable: false,
                render: renderCommentCount,
            },
            auditor: {
                title: t("Auditor"),
                filterFormatter: auditorFormatter,
            },
            status: {
                title: t("Status"),
                sortable: false,
                filterable: false,
            },
            actions: {
                title: t("Actions"),
                filterable: false,
                sortable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
    }, [
        auditorFormatter,
        renderActions,
        renderAuditButton,
        renderCommentCount,
        renderComplianceType,
        renderJobId,
        renderJobType,
        renderRemdialAction,
        status,
        t,
    ]);

    const bulkActions = useMemo<IBulkAction[] | undefined>(
        () =>
            status === "Approval Required"
                ? [
                      {
                          value: "audit",
                          label: t("Start auditing"),
                          onSubmit: startAuditing,
                      },
                      ...(canRemoveAuditHold && inbox
                          ? [
                                {
                                    value: "removeLock",
                                    label: t("Remove hold"),
                                    onSubmit: handleRemoveLock,
                                },
                            ]
                          : []),
                  ]
                : undefined,
        [handleRemoveLock, inbox, canRemoveAuditHold, startAuditing, status, t],
    );

    return lgsrs.loaded ? (
        <>
            <div
                className={
                    status === "Approval Required" ? styles.tableModifier : ""
                }
            >
                <Table
                    preferences="lgsr-audit-table"
                    columns={columns}
                    bulkActions={bulkActions}
                    alternateCsvFunction={handleDownloadClick}
                    actionButton={
                        <>
                            {status === "Approval Required" &&
                                lgsrs.records.length > 0 && (
                                    <Button
                                        variant="primary"
                                        onClick={startAuditing}
                                    >
                                        <Icon
                                            icon="chevron-right"
                                            ariaHidden={true}
                                        />
                                        {t("Start Auditing")}
                                    </Button>
                                )}
                        </>
                    }
                    hideChildComponent={csvReportToast.visible}
                    {...lgsrs}
                />
            </div>

            {confirmCloseVisible && (
                <Portal>
                    <Modal
                        title={t("Exit audit process")}
                        hide={confirmCloseHide}
                    >
                        <ModalBody>
                            <p>
                                {t(
                                    "You are about to leave the auditing process. Your current job will not be saved.",
                                )}
                            </p>
                            <p>{t("Are you sure you want to exit?")}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={auditModelCloseConfirm}>
                                {t("Exit")}
                            </Button>
                            &nbsp;
                            <Button onClick={confirmCloseHide}>
                                {t("Cancel")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Portal>
            )}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t(
                            "This document is currently being audited by another user",
                        )}
                    </Toast>
                </Portal>
            )}

            {auditingVisible && jobAudit.loaded && (
                <LgsrAuditApprover
                    goToNextLgsr={goToNextLgsr}
                    auditData={jobAudit.value}
                    hide={auditModalClosed}
                    onHoldHandler={confirmCloseHide}
                    readOnly={readOnlyMode}
                />
            )}

            {csvReportToast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    ) : (
        <Loading />
    );
};

interface IAuditsTableProps {
    inbox?: boolean;
    status: AuditStatus;
    csvReportName: AuditsCsvReportName;
    onAuditCompleted: () => void;
}

export default AuditsTable;
