import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Card,
    EditablePill,
    Link,
    Loading,
    Portal,
    PropertyCategoriesContext,
    Table,
} from "../../components";
import { ITableColumn } from "../../components/Table";
import Toast from "../../components/Toast";
import config from "../../config";
import { useHasPermission, useToast } from "../../hooks";
import useDownloadCsv from "../../hooks/useDownloadCsv";
import useQueryString from "../../hooks/useQueryString";
import { ILandlordEngineer, useEngineers } from "../../utils/api/landlords";
import { IFilters, IFilterToggle } from "../../utils/api/useFilterable";
import TableActions from "./TableActions";

const Engineers = () => {
    const { t } = useTranslation();
    const isPortalStandard = useHasPermission("portal_standard");

    const { selectedCategories } = useContext(PropertyCategoriesContext);

    const engineers = useEngineers({
        sortProperty: "name",
        sortDirection: "asc",
        filters: {
            propertyCategories: {
                readOnly: true,
                operator: "{OR}",
                filters: selectedCategories.map((c) => ({
                    function: "=",
                    value: c.displayName,
                })),
            },
        },
    });
    const { setFilters, goToPage, filters, loaded, search } = engineers;

    useEffect(() => {
        goToPage(1);
        setFilters({
            propertyCategories: {
                readOnly: true,
                operator: "{OR}",
                filters: selectedCategories.map((c) => ({
                    function: "=",
                    value: c.displayName,
                })),
            },
        });
    }, [goToPage, selectedCategories, setFilters]);

    const clearfilters = useCallback(
        (property: string) => {
            if (property === "propertyCategories") {
                goToPage(1);
                setFilters((f) => ({
                    ...f,
                    propertyCategories: {
                        readOnly: true,
                        operator: "{OR}",
                        filters:
                            selectedCategories.length > 0
                                ? selectedCategories.map((c) => ({
                                      function: "=",
                                      value: c.displayName,
                                  }))
                                : [],
                    },
                }));
            }
        },
        [goToPage, setFilters, selectedCategories],
    );

    const toggleFilter = useCallback(
        (toggle: IFilterToggle[]) => {
            goToPage(1);
            setFilters((oldFilters) => {
                const filterGroups: IFilters = {};

                for (const newfilter of toggle) {
                    if (newfilter.property === "propertyCategories") {
                        const oldFilter = oldFilters[newfilter.property];
                        let filtersArray =
                            oldFilter !== undefined
                                ? [...oldFilter.filters]
                                : [];

                        for (const filter of newfilter.filterGroup.filters) {
                            const index =
                                oldFilter !== undefined
                                    ? oldFilter.filters.findIndex(
                                          (f) => f.value === filter.value,
                                      )
                                    : -1;

                            if (index > -1) {
                                filtersArray.splice(index, 1);
                            } else {
                                filtersArray.push(filter);
                            }
                        }

                        if (
                            selectedCategories.length > 0 &&
                            filtersArray.length === 0
                        ) {
                            filtersArray = selectedCategories.map((c) => ({
                                function: "=",
                                value: c.displayName,
                            }));
                        }

                        filterGroups[newfilter.property] = {
                            readOnly: false,
                            operator: "{OR}",
                            filters: filtersArray,
                        };
                    }
                }

                return filterGroups;
            });
        },
        [goToPage, selectedCategories, setFilters],
    );
    const toast = useToast();
    const { getQueryString } = useQueryString(search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.landlordsApiUrl}/engineers/export`,
        filters,
        filterColumns: (vc) => vc.key !== "actions",
        search: getQueryString(),
        showToast: toast.show,
        csvReportName: "Engineers",
    });

    const columns = useMemo(() => {
        const renderName = (_: unknown, row: ILandlordEngineer) =>
            isPortalStandard ? (
                <Link url={`/management/engineers/${row.id}`}>{row.name}</Link>
            ) : (
                row.name
            );

        const renderActions = (_: unknown, row: ILandlordEngineer) => (
            <TableActions id={row.id} />
        );

        const col: { [key: string]: ITableColumn<ILandlordEngineer> } = {
            name: {
                title: t("Name"),
                filterable: false,
                canBeToggledByUser: false,
                render: renderName,
            },
            lastActiveDate: {
                title: t("Last Active"),
                filterable: false,
                type: "date",
            },
            propertyCategories: {
                title: t("Property Categories"),
                sortable: false,
                filterAvailableFilters: (allFilters) => {
                    return selectedCategories.length > 0
                        ? allFilters.filter((f) =>
                              selectedCategories.some(
                                  (c) => c.displayName === f,
                              ),
                          )
                        : allFilters;
                },
                render: (_, row) =>
                    row.propertyCategories.map((c) => (
                        <EditablePill
                            key={c.id}
                            name={c.displayName}
                            colour={c.colour}
                        />
                    )),
            },
            ...(isPortalStandard
                ? {
                      contractors: {
                          title: t("Contractors"),
                          filterable: false,
                          sortable: false,
                          render: (_, row) =>
                              row.contractors
                                  .map((contractor) => contractor.name)
                                  .join(", "),
                      },
                  }
                : {}),
            ...(isPortalStandard
                ? {
                      actions: {
                          title: t("Actions"),
                          filterable: false,
                          sortable: false,
                          canBeToggledByUser: false,
                          render: renderActions,
                      },
                  }
                : {}),
        };

        return col;
    }, [t, isPortalStandard, selectedCategories]);

    return (
        <>
            <Card
                title={t("Engineers")}
                actions={
                    isPortalStandard && (
                        <Link url="/management/engineers/add">
                            {t("Add new Engineer")}
                        </Link>
                    )
                }
            >
                {loaded ? (
                    <Table
                        preferences="engineers-table"
                        columns={columns}
                        {...engineers}
                        toggleFilter={toggleFilter}
                        clearFilters={clearfilters}
                        alternateCsvFunction={handleDownloadClick}
                        hideChildComponent={toast.visible}
                    />
                ) : (
                    <Loading />
                )}
            </Card>
            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default Engineers;
