import {
    AuditStatus,
    QualifiedSupervisorCsvReportName,
} from "../../../utils/api/audits";
import QualifiedSupervisorAuditsTable from "./QualifiedSupervisorAuditsTable";

export interface IQualifiedSupervisorAuditsTableProps {
    status: AuditStatus;
    csvReportName: QualifiedSupervisorCsvReportName;
    refreshCounts: () => void;
}

export default QualifiedSupervisorAuditsTable;
