import { keyBy, mapValues } from "lodash";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";
import {
    Alert,
    Button,
    Checkbox,
    ColourPill,
    Form,
    Grid,
    GridColumn,
    InputField,
    Loading,
    Modal,
    MultilineInputField,
    Portal,
    SearchableSelect,
    Select,
    TabGroup,
    TabGroupTab,
    ValidationError,
} from "..";
import {
    IAllocatedJob,
    IAwaitingAllocationJob,
    useCreateAllocatedJob,
    useCreateAwaitingAllocationJob,
    useGenerateReferenceNumber,
    useUpdateAllocatedJob,
} from "../../utils/api/allocatedJobs";
import {
    ILandlordEngineer,
    useLandlordPriorities,
} from "../../utils/api/landlords";
import {
    IDueDate,
    IPropertyFlag,
    ISimpleProperty,
} from "../../utils/api/properties";
import usePropertyInfo from "../../utils/api/properties/usePropertyInfo";
import { clearCache } from "../../utils/cache";
import {
    addHours,
    addMinutes,
    getDate,
    getDifferenceInDays,
    getNow,
    getToday,
    toDateString,
    toTimeString,
} from "../../utils/dates";
import {
    isRequired,
    IValidateField,
    useValidateField,
    validateForm,
} from "../../utils/validation";
import isTime from "../../utils/validation/isTime";
import InputDatePicker from "../InputDatePicker";
import ModalBody from "../Modal/ModalBody";
import ModalFooter from "../Modal/ModalFooter";
import ObservationsNotice from "../ObservationsNotice";
import { ISelectOption } from "../Select";
import styles from "./AllocateJobModal.module.scss";
import ContractorSelect from "./ContractorSelect";
import PropertyFlags from "./PropertyFlags";
import PropertyNotes from "./PropertyNotes";

const AllocateJobModal = ({
    allocatedJob,
    addressString,
    engineers,
    defaultJobDate,
    propertyId,
    property,
    validators,
    canSelectContractor,
    awaitingAllocationJob,
    loading = false,
    propertyCategoryId,
    propertyCategories,
    children,
    hide,
    onDeleteAllocatedJob,
    onManuallyCompleteAllocatedJob,
    onReconcileAllocatedJob,
    refresh = noop,
}: IAllocateJobModalProps) => {
    const { t } = useTranslation();

    const { value: landlordPriorities } = useLandlordPriorities();

    const referenceNumberGenerator = useGenerateReferenceNumber();

    const [engineerTabActive, setEngineerTabActive] = useState(true);

    const [selectedFlags, setSelectedFlags] = useState(
        mapValues(
            keyBy(
                JSON.parse((allocatedJob && allocatedJob.flags) || "[]"),
                (flag: { id: string }) => flag.id,
            ),
            () => true,
        ),
    );
    const [flags, setFlags] = useState<IPropertyFlag[]>([]);

    const handleSelectedFlagsChange = useCallback((list: IPropertyFlag[]) => {
        setFlags(list);
        setSelectedFlags(
            mapValues(
                keyBy(list, (flag) => flag.id),
                () => true,
            ),
        );
    }, []);

    const [complianceTypeId, setComplianceTypeId] = useState(
        allocatedJob?.complianceType.id.toString() ??
            propertyCategoryId?.toString() ??
            "",
    );

    const [propertyDueDates, setPropertyDueDates] = useState<IDueDate[]>([]);

    const { getProperty } = usePropertyInfo();

    useEffect(() => {
        if (property) {
            setPropertyDueDates(property.nextServiceDueDates);
        } else if (allocatedJob) {
            getProperty(allocatedJob.property.id.toString()).subscribe(
                (result) => {
                    setPropertyDueDates(result.nextServiceDueDates);
                },
            );
        } else if (awaitingAllocationJob) {
            getProperty(awaitingAllocationJob.property.id.toString()).subscribe(
                (result) => {
                    setPropertyDueDates(result.nextServiceDueDates);
                },
            );
        }
    }, [allocatedJob, awaitingAllocationJob, getProperty, property]);

    useEffect(() => {
        if (allocatedJob && allocatedJob.property.id === propertyId) {
            setComplianceTypeId(
                allocatedJob.complianceType &&
                    allocatedJob.complianceType.id.toString(),
            );
            setJobType(allocatedJob.jobType);
            setDescription(allocatedJob.description);
            setAppendTenantDetails(allocatedJob.appendTenantDetails);
        } else if (
            awaitingAllocationJob &&
            awaitingAllocationJob.property.id === propertyId
        ) {
            setComplianceTypeId(
                awaitingAllocationJob.complianceType &&
                    awaitingAllocationJob.complianceType.id.toString(),
            );
            setJobType(awaitingAllocationJob.jobType);
            setDescription(awaitingAllocationJob.description);
            setAppendTenantDetails(awaitingAllocationJob.appendTenantDetails);
        } else {
            setComplianceTypeId(propertyCategoryId?.toString() ?? "");
        }
    }, [
        allocatedJob,
        awaitingAllocationJob,
        getProperty,
        property,
        propertyCategoryId,
        propertyId,
    ]);

    useEffect(() => {
        if (!canSelectContractor) {
            setEngineerTabActive(true);
        }
    }, [canSelectContractor]);

    const handleClick = useCallback(() => {
        setExternalJobReference("");

        if (propertyId !== null) {
            referenceNumberGenerator
                .generateReferenceNumber(propertyId)
                .subscribe((newReferenceNumber) => {
                    setExternalJobReference(newReferenceNumber.referenceNumber);
                });
        }
    }, [propertyId, referenceNumberGenerator]);

    const [complianceTypeFilter, setComplianceTypeFilter] = useState("");

    const complianceTypeOptions = useMemo<ISelectOption[]>(() => {
        if (propertyDueDates && propertyDueDates.length) {
            const result = propertyDueDates
                .filter(
                    (dueDate) =>
                        !complianceTypeFilter ||
                        dueDate.fuelTypeName
                            .toLocaleLowerCase()
                            .includes(complianceTypeFilter.toLocaleLowerCase()),
                )
                .sort(
                    (a, b) =>
                        (a.date !== null
                            ? new Date(a.date)
                            : getToday()
                        ).getTime() -
                        (b.date !== null
                            ? new Date(b.date)
                            : getToday()
                        ).getTime(),
                )
                .map((dueDate) => {
                    const date =
                        dueDate.date !== null
                            ? new Date(dueDate.date)
                            : getToday();
                    const days = Math.floor(
                        getDifferenceInDays(getToday(), date),
                    );

                    return {
                        label: (
                            <div className={styles.complianceTypeDropdownItem}>
                                <Grid>
                                    <GridColumn size="thirtyPercent">
                                        <ColourPill
                                            fullWidth={true}
                                            textCenter={true}
                                            value={dueDate.fuelTypeName}
                                            customColour={dueDate.color}
                                        />
                                    </GridColumn>
                                    <GridColumn size="seventyPercent">
                                        {days > 0 ? (
                                            <>
                                                {t("Due in")}&nbsp;
                                                <span
                                                    className={
                                                        days > 30
                                                            ? styles.compliant
                                                            : styles.dueSoon
                                                    }
                                                >
                                                    {days}
                                                </span>
                                                &nbsp;
                                                {t("daysCount", {
                                                    count: days,
                                                })}
                                            </>
                                        ) : (
                                            <span className={styles.overdue}>
                                                {t("Overdue")}
                                            </span>
                                        )}
                                        &nbsp;({toDateString(date)})
                                    </GridColumn>
                                </Grid>
                            </div>
                        ),
                        value: dueDate.fuelTypeId.toString(),
                    };
                });

            return result;
        } else if (propertyCategories) {
            return propertyCategories.map((c) => ({
                value: c.id.toString(),
                label: c.displayName,
            }));
        }

        return [];
    }, [propertyDueDates, propertyCategories, complianceTypeFilter, t]);

    const complianceTypeValidator = useValidateField(
        complianceTypeId,
        isRequired(),
    );

    const [isCapped, nextServiceDue] = useMemo(() => {
        const service = property?.nextServiceDueDates.find(
            (s) => s.fuelTypeId === 1,
        );
        const serviceDue = service?.date
            ? Math.floor(
                  getDifferenceInDays(getToday(), new Date(service.date)),
              ) <= 60
            : false;
        return [(property && property.isCapped) || false, serviceDue];
    }, [property]);

    const [engineerId, setEngineerId] = useState(
        allocatedJob?.engineer.id.toString() ?? "",
    );
    const [externalJobReference, setExternalJobReference] = useState(
        allocatedJob?.externalJobReference ?? "",
    );

    const [contractorId, setContractorId] = useState("");
    const [jobType, setJobType] = useState(allocatedJob?.jobType ?? "");
    const [jobNumber, setJobNumber] = useState(allocatedJob?.jobNumber ?? "");
    const [partNumber, setPartNumber] = useState(
        allocatedJob?.partNumber ?? "",
    );

    const [jobDate, setJobDate] = useState(() => {
        let date: Date;

        if (allocatedJob) {
            date = getDate(new Date(allocatedJob.jobDate));
        } else if (defaultJobDate && defaultJobDate >= getToday()) {
            date = defaultJobDate;
        } else {
            date = getToday();
        }

        return date;
    });
    const [jobTime, setJobTime] = useState(
        toTimeString(
            (allocatedJob && new Date(allocatedJob.jobDate)) || getNow(),
        ),
    );
    const [estimatedJobTime, setEstimatedJobTime] = useState(
        toTimeString(
            new Date(
                0,
                0,
                0,
                allocatedJob?.estimatedTimeHours ?? 0,
                allocatedJob?.estimatedTimeMinutes ?? 0,
            ),
        ),
    );
    const [description, setDescription] = useState(
        allocatedJob?.description ?? "",
    );
    const [appendTenantDetails, setAppendTenantDetails] = useState(
        allocatedJob?.appendTenantDetails ?? false,
    );
    const handleAppendTenantDetailsChange = (checked: boolean) => {
        setAppendTenantDetails(checked);
    };
    const [landlordPriorityId, setLandlordPriorityId] = useState(
        (allocatedJob?.landlordPriorityId ?? "").toString(),
    );

    const showLandlordPriority = useMemo(
        () => jobType === "Repair" && landlordPriorities.length > 0,
        [jobType, landlordPriorities],
    );

    const jobTypeValidator = useValidateField(jobType, isRequired());
    const jobDateValidator = useValidateField(jobDate, isRequired());
    const jobTimeValidator = useValidateField(jobTime, isRequired(), isTime());
    const landlordPriorityIdValidator = useValidateField(
        landlordPriorityId,
        isRequired(),
    );
    const estimatedJobTimeValidator = useValidateField(
        estimatedJobTime,
        isRequired(),
        isTime(),
    );
    const engineerIdValidator = useValidateField(engineerId, isRequired());
    const contractorIdValidator = useValidateField(contractorId, isRequired());
    const formValidator = validateForm(() => {
        const list: IValidateField[] = [
            jobTypeValidator,
            jobDateValidator,
            jobTimeValidator,
            estimatedJobTimeValidator,
            complianceTypeValidator,
        ];

        if (showLandlordPriority) {
            list.push(landlordPriorityIdValidator);
        }

        if (validators) {
            list.push(...validators);
        }

        list.push(
            engineerTabActive ? engineerIdValidator : contractorIdValidator,
        );

        return list;
    });

    const createAllocatedJob = useCreateAllocatedJob();
    const createAwaitingAllocationJob = useCreateAwaitingAllocationJob();
    const { loading: updateAllocatedJobLoading, updateAllocatedJob } =
        useUpdateAllocatedJob();

    const handleDeleteJobClick = useCallback(() => {
        if (onDeleteAllocatedJob && allocatedJob) {
            onDeleteAllocatedJob(allocatedJob.id);
        }
    }, [allocatedJob, onDeleteAllocatedJob]);

    const handleFormSubmit = () => {
        const estimatedTime = estimatedJobTime.split(":");
        const time = jobTime.split(":");

        const dateTime = addHours(
            addMinutes(jobDate, Number(time[1] || "0")),
            Number(time[0] || "0"),
        );

        if (allocatedJob) {
            const foundEngineer = engineers.find(
                (e) => e.id === Number(engineerId),
            );

            const selectedEngineer = foundEngineer
                ? {
                      id: foundEngineer.id,
                      name: foundEngineer.name,
                      imageUrl: foundEngineer.image?.key || "",
                  }
                : allocatedJob.engineer;

            const updatedAllocatedJob = {
                appendTenantDetails,
                description,
                jobType,
                landlordPriorityId:
                    showLandlordPriority && landlordPriorityId
                        ? Number(landlordPriorityId)
                        : null,
                propertyId: propertyId !== null ? Number(propertyId) : null,
                engineerId:
                    engineerTabActive && engineerId ? Number(engineerId) : null,
                engineer: selectedEngineer,
                contractorId:
                    !engineerTabActive && contractorId
                        ? Number(contractorId)
                        : null,
                jobNumber,
                externalJobReference: externalJobReference || null,
                partNumber,
                flags: JSON.stringify(flags),
                estimatedTimeHours: Number(estimatedTime[0] || "0"),
                estimatedTimeMinutes: Number(estimatedTime[1] || "0"),
                jobDate: dateTime.toISOString(),
                complianceTypeId: Number(complianceTypeId),
            };

            updateAllocatedJob(allocatedJob.id, {
                ...allocatedJob,
                ...updatedAllocatedJob,
            }).subscribe(() => hide());
        } else {
            const newAllocatedJob = {
                awaitingAllocationJobId: awaitingAllocationJob?.id ?? null,
                appendTenantDetails,
                description,
                jobType,
                landlordPriorityId:
                    showLandlordPriority && landlordPriorityId
                        ? Number(landlordPriorityId)
                        : null,
                propertyId: propertyId !== null ? Number(propertyId) : null,
                engineerId:
                    engineerTabActive && engineerId ? Number(engineerId) : null,
                contractorId:
                    !engineerTabActive && contractorId
                        ? Number(contractorId)
                        : null,
                jobNumber,
                externalJobReference: externalJobReference || null,
                partNumber,
                flags: JSON.stringify(flags),
                estimatedTimeHours: Number(estimatedTime[0] || "0"),
                estimatedTimeMinutes: Number(estimatedTime[1] || "0"),
                jobDate: dateTime.toISOString(),
                complianceTypeId: Number(complianceTypeId),
            };

            if (engineerTabActive) {
                createAllocatedJob
                    .createAllocatedJob(newAllocatedJob)
                    .subscribe(() => {
                        clearCache();
                        refresh();
                        hide();
                    });
            } else {
                createAwaitingAllocationJob
                    .createAwaitingAllocationJob(newAllocatedJob)
                    .subscribe(() => {
                        clearCache();
                        refresh();
                        hide();
                    });
            }
        }
    };

    const [engineersFilter, setEngineersFilter] = useState("");
    const engineersOptions = useMemo<ISelectOption[]>(
        () =>
            engineers
                .filter((engineer) =>
                    engineer.name
                        ? engineer.name
                              .toLowerCase()
                              .includes(engineersFilter.toLowerCase())
                        : false,
                )
                .map((engineer) => ({
                    label: engineer.name,
                    value: engineer.id.toString(),
                })),
        [engineers, engineersFilter],
    );

    const landlordPrioritiesOptions = useMemo<ISelectOption[]>(() => {
        return landlordPriorities.map((priority) => ({
            label: priority.priority,
            value: priority.id.toString(),
        }));
    }, [landlordPriorities]);

    const jobTypes = useMemo<ISelectOption[]>(
        () => [
            { label: t("Repair"), value: "Repair" },
            { label: t("Service"), value: "Service" },
            { label: t("Install"), value: "Install" },
            { label: t("Other"), value: "Other" },
        ],
        [t],
    );

    const isBeforeToday = useMemo(
        () => !!allocatedJob && new Date(allocatedJob.jobDate) <= getToday(),
        [allocatedJob],
    );

    const showManuallyCompleteJob = useMemo(() => {
        return !allocatedJob?.isComplete && isBeforeToday;
    }, [isBeforeToday, allocatedJob]);

    const isEditDisabled = useMemo(() => {
        return allocatedJob?.isComplete || isBeforeToday;
    }, [isBeforeToday, allocatedJob]);

    const handleEngineerTabActivate = useCallback((index: number) => {
        const isActive = index === 0;

        setEngineerTabActive(isActive);
        if (isActive) {
            setContractorId("");
        } else {
            setEngineerId("");
        }
    }, []);

    return (
        <Portal>
            <Form onSubmit={handleFormSubmit} {...formValidator}>
                <Modal
                    hide={hide}
                    title={
                        addressString
                            ? `${t("Allocate job")}: ${addressString}`
                            : t("Job Details")
                    }
                >
                    <ModalBody>
                        {children}

                        {nextServiceDue && (
                            <p className={styles.cappedLabel}>
                                {t(
                                    "Gas Service Date is overdue/due soon, you " +
                                        "should schedule a Gas service for this property",
                                )}
                            </p>
                        )}

                        {isCapped && (
                            <p className={styles.cappedLabel}>
                                {t("This property is currently capped")}
                            </p>
                        )}

                        {propertyId !== null && (
                            <PropertyNotes propertyId={propertyId} />
                        )}

                        {propertyId !== null && (
                            <TabGroupTab header={t("Compliance type")}>
                                <SearchableSelect
                                    label={t("Compliance type")}
                                    placeholder={t("Search")}
                                    options={complianceTypeOptions}
                                    value={complianceTypeId}
                                    onChange={setComplianceTypeId}
                                    applySearch={setComplianceTypeFilter}
                                    {...complianceTypeValidator}
                                />
                            </TabGroupTab>
                        )}

                        {propertyId !== null && complianceTypeId === "4" && (
                            <ObservationsNotice
                                propertyId={Number(propertyId)}
                            />
                        )}

                        <TabGroup
                            headersPosition="top"
                            onActivate={handleEngineerTabActivate}
                            currentTab={engineerTabActive ? 0 : 1}
                        >
                            <TabGroupTab header={t("Engineer")}>
                                <SearchableSelect
                                    label={t("Engineer")}
                                    placeholder={t("Search")}
                                    options={engineersOptions}
                                    value={engineerId}
                                    onChange={setEngineerId}
                                    applySearch={setEngineersFilter}
                                    {...engineerIdValidator}
                                />

                                {propertyId !== null && (
                                    <Grid>
                                        <GridColumn size="eightyPercent">
                                            <InputField
                                                label={t(
                                                    "External Job Reference",
                                                )}
                                                value={externalJobReference}
                                                readOnly={true}
                                                append={
                                                    referenceNumberGenerator.loading && (
                                                        <Loading small={true} />
                                                    )
                                                }
                                            />
                                        </GridColumn>

                                        <GridColumn
                                            size="twentyPercent"
                                            cssRules={{
                                                paddingTop: "23px",
                                            }}
                                        >
                                            <Button
                                                variant="primary"
                                                onClick={handleClick}
                                                displayBlock={true}
                                                disabled={
                                                    referenceNumberGenerator.loading
                                                }
                                            >
                                                {t("Generate")}
                                            </Button>
                                        </GridColumn>
                                    </Grid>
                                )}
                            </TabGroupTab>

                            {canSelectContractor && (
                                <TabGroupTab header={t("Contractor")}>
                                    <ContractorSelect
                                        contractorId={contractorId}
                                        setContractorId={setContractorId}
                                        validator={contractorIdValidator}
                                    />
                                </TabGroupTab>
                            )}
                        </TabGroup>

                        <Select
                            label={t("Job type")}
                            options={jobTypes}
                            value={jobType}
                            onChange={setJobType}
                            {...jobTypeValidator}
                        />

                        {showLandlordPriority && (
                            <Select
                                label={t("Job priority")}
                                allowEmpty={true}
                                value={landlordPriorityId.toString()}
                                options={landlordPrioritiesOptions}
                                onChange={setLandlordPriorityId}
                                {...landlordPriorityIdValidator}
                            />
                        )}

                        <InputField
                            label={t("Estimated job time")}
                            type="time"
                            value={estimatedJobTime}
                            onChange={setEstimatedJobTime}
                            {...estimatedJobTimeValidator}
                        />

                        <MultilineInputField
                            label={t("Job number")}
                            value={jobNumber}
                            onChange={setJobNumber}
                        />

                        <MultilineInputField
                            label={t("Part number")}
                            value={partNumber}
                            onChange={setPartNumber}
                        />

                        <MultilineInputField
                            label={t("Notes for engineer")}
                            value={description}
                            onChange={setDescription}
                        />

                        <InputDatePicker
                            label={t("Job date")}
                            date={jobDate}
                            onDateSelected={setJobDate}
                            {...jobDateValidator}
                        />

                        <InputField
                            label={t("Job time")}
                            type="time"
                            value={jobTime}
                            onChange={setJobTime}
                            {...jobTimeValidator}
                        />

                        <Checkbox
                            checked={appendTenantDetails}
                            onChange={handleAppendTenantDetailsChange}
                        >
                            {t("Append tenant details?")}
                        </Checkbox>

                        {propertyId !== null && (
                            <PropertyFlags
                                propertyId={propertyId}
                                selectedFlags={selectedFlags}
                                onSelectedFlagsChange={
                                    handleSelectedFlagsChange
                                }
                            />
                        )}

                        <ValidationError
                            error={
                                createAllocatedJob.error === "Conflict"
                                    ? t(
                                          "External Job Reference must be re-generated",
                                      )
                                    : createAllocatedJob.error
                            }
                            isValid={!createAllocatedJob.error}
                        />
                        {createAwaitingAllocationJob.error && (
                            <Alert type="error">
                                {createAwaitingAllocationJob.error}
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {updateAllocatedJobLoading ||
                        createAllocatedJob.loading ||
                        createAwaitingAllocationJob.loading ||
                        loading ? (
                            <Loading small={true} />
                        ) : (
                            <>
                                {showManuallyCompleteJob &&
                                    onReconcileAllocatedJob && (
                                        <Button
                                            onClick={onReconcileAllocatedJob}
                                            cssRules={{
                                                marginRight: "10px",
                                            }}
                                        >
                                            {t("Reconcile Job")}
                                        </Button>
                                    )}
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isEditDisabled}
                                >
                                    {t("Save")}
                                </Button>

                                {allocatedJob && handleDeleteJobClick && (
                                    <Button
                                        variant="error"
                                        onClick={handleDeleteJobClick}
                                        cssRules={{ marginLeft: "10px" }}
                                        disabled={
                                            allocatedJob.isComplete ||
                                            allocatedJob.isIntegrationJob
                                        }
                                    >
                                        {t("Delete")}
                                    </Button>
                                )}

                                {showManuallyCompleteJob &&
                                    onManuallyCompleteAllocatedJob && (
                                        <Button
                                            variant="primary"
                                            onClick={
                                                onManuallyCompleteAllocatedJob
                                            }
                                            cssRules={{
                                                marginLeft: "10px",
                                            }}
                                        >
                                            {t("Manually Complete Job")}
                                        </Button>
                                    )}
                            </>
                        )}
                    </ModalFooter>
                </Modal>
            </Form>
        </Portal>
    );
};

interface IAllocateJobModalProps {
    propertyCategoryId?: number;
    propertyCategories?: { id: number; displayName: string }[];
    allocatedJob?: IAllocatedJob;
    engineers: ILandlordEngineer[];
    addressString?: string;
    defaultJobDate?: Date;
    awaitingAllocationJob?: IAwaitingAllocationJob;
    canSelectContractor: boolean;
    propertyId: number | null;
    loading?: boolean;
    property?: ISimpleProperty;
    children?: ReactNode;
    validators?: IValidateField[];
    hide: () => void;
    onManuallyCompleteAllocatedJob?: () => void;
    onReconcileAllocatedJob?: () => void;
    onDeleteAllocatedJob?: (jobId: string) => void;
    refresh?: () => void;
}

export default AllocateJobModal;
