import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IQualifiedSupervisorAuditsTableProps } from ".";
import {
    Alert,
    ColourPill,
    JobType,
    Link,
    Portal,
    UserContext,
} from "../../../components";
import Table, { IBulkAction, ITableColumn } from "../../../components/Table";
import Toast from "../../../components/Toast";
import config from "../../../config";
import {
    useHasUserParentPermissionFunction,
    useToast,
    useToggle,
} from "../../../hooks";
import useDownloadCsv from "../../../hooks/useDownloadCsv";
import useQueryString from "../../../hooks/useQueryString";
import {
    IComplianceType,
    ISimpleAudit,
    useAudits,
    useBulkRemoveHoldAudit,
} from "../../../utils/api/audits";
import { clearCache } from "../../../utils/cache";
import QualifiedSupervisorAuditsModal from "../QualifiedSupervisorAuditsModal";
import TableActions from "../TableActions";
import UserSignatureModal from "../UserSignatureModal";
import styles from "./QualifiedSupervisorAuditsTable.module.scss";

const QualifiedSupervisorAuditsTable = ({
    status,
    csvReportName,
    refreshCounts,
}: IQualifiedSupervisorAuditsTableProps) => {
    const { t } = useTranslation();

    const { user } = useContext(UserContext);

    const { removeHoldAudit, error: removeHoldError } =
        useBulkRemoveHoldAudit();
    const audits = useAudits(status);
    const { filters, records, refresh, search } = audits;

    const toast = useToast();
    const { getQueryString } = useQueryString(search);
    const { handleDownloadClick } = useDownloadCsv({
        exportDataUrl: `${config.auditsApiUrl}/export`,
        filters,
        filterColumns: (vc) => vc.key !== "id" && vc.key !== "actions",
        search: getQueryString(),
        csvReportName,
        showToast: toast.show,
    });

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [auditList, setAuditList] = useState<number[]>([]);
    const auditId = useMemo(
        () => (auditList.length > 0 ? auditList[0] : null),
        [auditList],
    );

    const { show, hide, visible } = useToggle();

    const closeAudit = useCallback(() => {
        if (!isReadOnly) {
            clearCache();
            refresh();
            refreshCounts();
        }

        hide();
    }, [hide, isReadOnly, refresh, refreshCounts]);

    const auditNext = useCallback(() => {
        if (auditList.length > 1) {
            setAuditList(auditList.slice(1));
        } else {
            closeAudit();
        }
    }, [auditList, closeAudit]);

    const hasRequiredPermission = useHasUserParentPermissionFunction();

    const addAdditionalAudits = useCallback(
        (ids: number[]) => {
            // Each item in the array depends on all items with a smaller index. In order to process the items without any dependencies first, we need to reverse the list.
            const reversedIds = ids.reverse();
            const list = [...auditList];

            for (const id of reversedIds) {
                const index = list.indexOf(id);

                if (index > -1) {
                    list.splice(index, 1);
                }

                list.unshift(id);
            }

            setAuditList(list);
        },
        [auditList],
    );

    const [error, setError] = useState("");

    const removeHold = useCallback(
        (ids: number[]) => {
            setError("");
            const list = records.filter((r) => ids.includes(r.id));
            const filteredList: number[] = [];

            for (const audit of list) {
                if (
                    audit.assignedUser?.id === user.id ||
                    hasRequiredPermission(
                        "portal_admin",
                        audit.job.property.landlord.userId,
                    )
                ) {
                    filteredList.push(audit.id);
                }
            }

            if (filteredList.length > 0) {
                removeHoldAudit(filteredList).subscribe(() => {
                    clearCache();
                    refresh();
                    refreshCounts();
                });
            } else {
                setError(
                    "You can't remove the hold on any of the selected QS records.",
                );
            }
        },
        [
            hasRequiredPermission,
            records,
            refresh,
            refreshCounts,
            removeHoldAudit,
            user.id,
        ],
    );

    const viewAudits = useCallback(
        (ids: number[]) => {
            setError("");
            setIsReadOnly(true);

            let list = records.map((r) => r.id);
            if (ids.length > 0) {
                list = list.filter((id) => ids.includes(id));
            }

            if (list.length > 0) {
                setAuditList(list);
                show();
            } else {
                setError("You can't view any of the selected QS records.");
            }
        },
        [records, show],
    );

    const startAuditing = useCallback(
        (ids: number[]) => {
            setError("");
            setIsReadOnly(false);

            let list = records
                .filter((r) => !r.assignedUser || r.assignedUser.id === user.id)
                .map((r) => r.id);

            if (ids.length > 0) {
                list = list.filter((id) => ids.includes(id));
            }

            if (list.length > 0) {
                setAuditList(list);
                show();
            } else {
                setError("You can't audit any of the selected QS records.");
            }
        },
        [records, show, user],
    );

    const columns = useMemo(() => {
        const renderActions = (value: unknown, row: ISimpleAudit) => (
            <TableActions
                status={status}
                id={row.id}
                userParentId={row.job.property.landlord.userId}
                removeHold={removeHold}
                startAuditing={startAuditing}
                viewAudits={viewAudits}
                auditorUserId={row.assignedUser?.id}
            />
        );

        const renderJobId = (value: number) => (
            <Link url={`/jobs/jobs/${value}`}>#{value}</Link>
        );

        const renderPropertyAddress = (value: string, row: ISimpleAudit) => (
            <Link url={`/management/properties/${row.job.property.id}`}>
                {value}
            </Link>
        );

        const renderComplianceType = (value: IComplianceType) => (
            <ColourPill
                customColour={value.colour}
                value={t(value.displayName)}
            />
        );

        const renderJobType = (value: string) => <JobType jobType={value} />;

        const col: { [key: string]: ITableColumn<ISimpleAudit> } = {
            "job.id": {
                title: t("Job Id"),
                sortable: false,
                filterable: false,
                canBeToggledByUser: false,
                render: renderJobId,
            },
            "job.property.addressString": {
                title: t("Address"),
                render: renderPropertyAddress,
            },
            createdAt: {
                title: t("Date"),
                type: "date",
            },
            "job.engineer.name": {
                title: t("Engineer"),
            },
            "job.complianceType": {
                title: t("Compliance category"),
                render: renderComplianceType,
                sortable: false,
                filterable: false,
            },
            "job.jobType": {
                title: t("Job type"),
                render: renderJobType,
            },
            childAuditsCount: {
                title: t("Sub jobs count"),
                type: "number",
                canBeToggledByUser: false,
                hidden: status === "Passed" || status === "Failed",
            },
            "assignedUser.name": {
                title: t("Qualified supervisor"),
            },
            documentType: {
                title: t("Document type"),
                render: (value: string | null) =>
                    value === null ? "Multi" : value,
                filterFormatter: (value: string | null) =>
                    value === null ? "Multi" : value,
            },
            actions: {
                title: t("Actions"),
                sortable: false,
                filterable: false,
                canBeToggledByUser: false,
                render: renderActions,
            },
        };

        return col;
    }, [removeHold, startAuditing, status, t, viewAudits]);

    const bulkActions = useMemo(() => {
        if (status === "Waiting" || status === "Hold") {
            const actions: IBulkAction[] = [];

            actions.push({
                value: "audit",
                label: t("Start QS"),
                onSubmit: startAuditing,
            });

            if (status === "Hold") {
                actions.push({
                    value: "hold",
                    label: t("Remove hold"),
                    onSubmit: removeHold,
                });
            }

            return actions;
        }
    }, [removeHold, startAuditing, status, t]);

    return (
        <>
            {(error || removeHoldError) && (
                <div className={styles.alert}>
                    <Alert type="error">{error || removeHoldError}</Alert>
                </div>
            )}

            <Table
                preferences="qs-audit-table"
                columns={columns}
                bulkActions={bulkActions}
                {...audits}
                alternateCsvFunction={handleDownloadClick}
                hideChildComponent={toast.visible}
            />

            {visible &&
                auditId !== null &&
                (isReadOnly || user.signatureUrl ? (
                    <QualifiedSupervisorAuditsModal
                        hide={closeAudit}
                        auditNext={auditNext}
                        addAdditionalAudits={addAdditionalAudits}
                        auditId={auditId}
                        isReadOnly={isReadOnly}
                    />
                ) : (
                    <UserSignatureModal hide={hide} />
                ))}

            {toast.visible && (
                <Portal>
                    <Toast>
                        {t("Generating report. Check CSV Reports tab.")}
                    </Toast>
                </Portal>
            )}
        </>
    );
};

export default QualifiedSupervisorAuditsTable;
